// App.js or any other component file
import React from 'react';
import './App.css'; // Import your CSS file

class OpenFacebookButton extends React.Component {
  openFacebookPage = (event) => {
    event.preventDefault(); // Prevent the default action of the anchor tag
    // Construct the Facebook page URL
    const facebookPageUrl = 'https://www.facebook.com/profile.php?id=100057109403696'; // Replace 'your-page-url' with the actual URL of the Facebook page
    const facebookUrl = `fb://facewebmodal/f?href=${encodeURIComponent(facebookPageUrl)}`;

    // Attempt to open the Facebook app with the Facebook page URL
    window.location.href = facebookUrl;
  };

  render() {
    return (
      <button onClick={this.openFacebookPage} className="btn-purple btn-lg" aria-label="Open Facebook Page">
        Open Page
      </button>
    );
  }
}

export default OpenFacebookButton;
